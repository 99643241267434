<template>
  <div v-show="isShow">
      <div class="confirm-dialog-box">
          <div class="text">为了更好的使用服务，登录前请先阅读并同意<span @click="goUserAgreement">《用户协议》</span>和<span @click="goPrivatePolicy">《隐私政策》</span>，未注册手机号将自动注册。</div>
          <div class="confirm">
              <div class="cancel" @click="show">不同意</div>
              <div class="button" @click="handleAgree">
                  <span v-show="!isLoading">同意并继续</span>
                  <van-loading color="#ea6f7b" size="24px" v-show="isLoading"/>
              </div>
          </div>
      </div>
      <div class="shadow" @click="show"></div>
  </div>
</template>

<script>
export default {
name: 'ConfirmAgreement',
components: {
},
data(){
    return {
        isShow: false,
        isLoading: false,
        text:'',
        type:'',
    }
},
methods:{
      showLoading(){
          this.isLoading = !this.isLoading
      },
      show(type){
          this.isShow = !this.isShow
          if(type){
              this.type = type
          }
          if(type === 'agreement'){
              this.text = '为了更好的使用服务，登录前请先阅读并同意《用户协议》和《隐私政策》，未注册手机号将自动注册。'
          } else {
              this.text = ''
          }
      },
      goUserAgreement(){
        this.$router.push('/about/user-agreement')
      },
      goPrivatePolicy(){
        this.$router.push('/about/privacy-policy')
      },
      handleAgree(){
        this.$emit('handleAgree', this.type)
      },
},
props:{

}
}
</script>

<style scoped lang="scss">
.shadow {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.3);
}
.confirm-dialog-box {
  width: 80%;
  max-width: 625px;
  margin: 0 auto;
  color: #000;
  background: #FFF;
  max-width: 625px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  position: fixed;
  border-radius: 5px;
  top: 30%;
  z-index: 999;
  .text {
      text-align: left;
      font-size: 16px;
      line-height: 25px;
      padding: 20px 15px;
      border-bottom: 1px solid #cdcdcd;
      span {
        color: #222;
        font-weight: 600;
      }
  }
  .confirm {
      display: flex;
      line-height: 50px;
      .button {
          width: 50%;
          font-size: 16px;
          color: #eb411f;
      }
      .cancel {
          width: 50%;
          font-size: 16px;
          color: #000;
          border-right: 1px solid #cdcdcd;
      }
  }
}
.van-loading {
  position: relative !important;
  left: 0;
  right: 0;
  top: 2px;
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  font-size: 0;
  vertical-align: middle;
  -ms-transform: none;
}
</style>
