<template>
  <div v-show="!isLoooading">
    <div v-if="$store.state.userAgent === 'PC'">
        <div class="bg-img">
            <!-- <img src="../assets/images/login-bg.jpg" alt="" width="100%" height="100%"> -->
        </div>
        <div class="login-pc-box" id="login-pc-box">
            <div class="login-card">
                <!-- <div class="left">
                    <div>
                        <img src="../../assets/images/login-text.png" alt="" width="200">
                    </div>
                    <div style="margin: 5px 0;">
                        <img src="../../assets/images/qrcode-gzh.png" alt="" width="155" height="155">
                    </div>
                    <div>打开微信App扫描二维码</div>
                </div> -->
                <div class="right">
                    <div class="title">{{ appName }}登录</div>
                    <!-- <div class="subtitle"><span>未注册的手机号验证通过后将自动注册</span></div> -->
                    <div>
                        <div class="input">
                            <div class="input-block">
                                <span>+86</span>
                                <input type="number" placeholder="输入手机号" v-model="phoneNumber"
                                oninput="if(value.length>11)value=value.slice(0,11)" ref="input"
                                @input="verifyPhoneNumber" maxlength="11">
                            </div>
                            <!-- <div class="tips" v-show="wrongNumber">手机号码格式不对</div> -->
                        </div>
                        <div class="input-verify">
                            <div class="input-block">
                                <input type="number" placeholder="输入验证码" v-model="codeNumber"
                                oninput="if(value.length>6)value=value.slice(0,6)" ref="input"
                                @keyup.enter="verifyIdentity" maxlength="6">
                                <span class="code-highlight" v-if="phoneNumber.length === 11 && !showResend" @click="sendVerifyCode">发送验证码</span>
                                <span class="code-default" v-else-if="phoneNumber.length === 11 && showResend">重新发送({{ time }}s)</span>
                                <span class="code-default" v-else>发送验证码</span>
                            </div>
                            <!-- <div class="tips" v-show="wrongNumber">手机号码格式不对</div> -->
                        </div>
                        <div class="input-verify" v-if="requireInvitationCode">
                            <div class="input-block" style="justify-content:start">
                                <input type="string" placeholder="输入邀请码" v-model="inviteCode"
                                oninput="if(value.length>18)value=value.slice(0,18)" ref="input"
                                @keyup.enter="verifyInvitationCode" maxlength="18">
                            </div>
                        </div>
                    </div>
                    <div class="button-block">
                        <div class="button-highlight" v-if="phoneNumber.length > 0 && codeNumber.length > 0 && (!requireInvitationCode || requireInvitationCode && inviteCode.length > 0)" @click="verify">
                            <van-loading color="white" size="24px" v-show="isLoading"/>
                            <span v-show="!isLoading">登录</span>
                        </div>
                        <div class="button" v-else>登录</div>
                    </div>
                    <div class="agreement">
                        <span>我已经阅读并同意《用户协议》《隐私政策》</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <img src="../../assets/images/beian.png" alt="" width="16" height="16">
          <a href="http://beian.miit.gov.cn/">{{ beianId }}</a>
        </div>
    </div>
    <div v-else>
        <div v-if="configInvitationCode">
            <van-notice-bar
                color="#555"
                background="#fdfbfb"
                left-icon="volume-o"
                scrollable
                text="欢迎您来，使用邀请码，加入内测..."
            />
        </div>
        <img src="../../assets/images/back.png" alt="" width="30" height="30"
             class="back-logo" @click="goBack" v-show="hasFromPath">
        <div class="login-box" id="login-box">
            <div class="title">
                <img src="../../assets/images/nav.png" alt="" width="30" height="30">
                登陆后{{ $route.params.text ?  $route.params.text : '继续用' }}
            </div>
            <div class="subtitle"><span>未注册的手机号验证通过后将自动注册</span></div>
            <div class="input">
                <div class="input-block">
                    <input type="number" placeholder="请输入手机号" v-model="phoneNumber"
                    oninput="if(value.length>11)value=value.slice(0,11)" ref="input"
                    @input="verifyPhoneNumber" @keyup.enter="sendVerifyCode" maxlength="11">
                </div>
                <div class="tips" v-show="wrongNumber">手机号码格式不对</div>
            </div>
            <div class="button" @click="sendVerifyCode">
                    <van-loading color="white" size="24px" v-show="isLoading"/>
                    <span v-show="!isLoading">发送短信验证码</span>
            </div>
            <div class="checkbox">
                <van-checkbox v-model="checked" icon-size="14px" checked-color="#313b46"></van-checkbox>
                <div class="agreement">
                    <span @click="handleChecked">登录注册即代表您已同意</span>
                    <span @click="goUserAgreement" class="content">《用户协议》</span>、<span @click="goPrivatePolicy" class="content">《隐私政策》</span>
                </div>
            </div>
        </div>
    </div>
    <ConfirmAgreement ref="confirm" @handleAgree="handleAgree" />
  </div>
</template>

<script>
import { sms, queryUserByPhoneNum, createUser, followAuthor, queryCodeNoUsed, updateInvitationByCode, login } from '@/api/api'
import ConfirmAgreement from '../../components/dialog/ConfirmAgreement.vue'
const seo = require('../../../config/index')

export default {
  name: 'Login',
  components: {
    ConfirmAgreement
  },
  mounted(){
        if(navigator.userAgent.indexOf('Safari')!=-1){
            document.getElementsByTagName("input")[0].type = "tel"
        }
        this.$refs.input.focus()
        // 优化路由切换时体验
        this.loadTimer = setTimeout(()=>{
            this.isLoooading = false
        },0)
        this.hasFromPath = sessionStorage.fromPath ? true : false
  },
  data:()=>{
    return {
        isLoooading: true,
        isLoading: false,
        showResend: false,
        phoneNumber:'',
        codeNumber:'',
        time: 30,
        beianId: seo.config.beianId,
        appName: seo.config.appName,
        wrongNumber: false,
        hasFromPath: false,
        inviteCode: '', // 邀请码
        identifyCode:'', // 回传的验证码
        configInvitationCode: seo.config.requireInvitationCode,
        requireInvitationCode: false, // 如需邀请码，未邀请用户会出现邀请码输入框
        checked:'',
    }
  },
  destroyed(){
    clearTimeout(this.loadTimer)
  },
  methods:{
      sendVerifyCode(){
        if(!this.wrongNumber && this.phoneNumber && !this.isLoading){
            if(this.checked){
              if(this.$store.state.userAgent !== 'PC'){
                  this.isLoading = true
              }
              sms({"phoneNumber": this.phoneNumber}).then((res) => {
                  if(res.data.code === 200){
                      this.identifyCode = res.data.identifyCode.slice(5,11)
                      localStorage.setItem('phoneNumber', this.phoneNumber)
                      if(this.$store.state.userAgent !== 'PC'){
                          setTimeout(()=>{
                              this.isLoading = false
                              sessionStorage.setItem('verifyCode', this.identifyCode)
                              if(this.$route.query.tid){
                                  this.$router.push({ path:'/verify', query:{ tid: this.$route.query.tid } })
                              } else {
                                  this.$router.push('/verify')
                              }
                          },1000)
                      } else {
                          this.showResend = true
                          this.time = 30
                          this.timer()
                      }
                  }
              }).catch((error) => {
                  this.isLoading = false
                  console.warn(error)
              })
            } else {
                this.$refs.input.blur()
                this.$refs.confirm.show('agreement')
            }
        }
      },
      async verifyPhoneNumber(){
          let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          if(reg_tel.test(this.phoneNumber)){
              this.wrongNumber = false
          } else {
              this.wrongNumber = true
          }
          // 输入满11位数自动发送验证码
          if(this.phoneNumber.length === 11){
            if(seo.config.requireInvitationCode){
                let res = await queryUserByPhoneNum({ phoneNum: this.phoneNumber })
                this.$nextTick(()=>{
                    if(res.data.data.length > 0 && !res.data.data[0].invited || res.data.data.length === 0){
                        this.requireInvitationCode = true
                    } else {
                        this.requireInvitationCode = false
                    }
                })
            }
            this.sendVerifyCode()
          }
      },
      verify(){
        if(this.requireInvitationCode){
            this.verifyInvitationCode()
        } else {
            this.verifyIdentity()
        }
      },
      verifyIdentity(){
            // 请求接口，核对验证码，成功保存token
            if(this.codeNumber === this.identifyCode && !this.isLoading){
                this.$refs.input.blur()
                this.$store.commit('Set_IsLogin_State', true)
                this.isLoading = true
                queryUserByPhoneNum({ phoneNum: this.phoneNumber }).then((res)=>{
                    if(res.data.data.length > 0){
                        login({ uid: res.data.data[0].uid }).then(result=>{
                            localStorage.setItem('token', result.data.token)
                        })
                    }
                    // 数据库中用户不存在则注册未新用户
                    if(res.data.data.length === 0){
                        createUser({ phoneNum: this.phoneNumber }).then((res)=>{
                            console.log('成功注册新用户')
                            localStorage.setItem('userInfo', JSON.stringify(res.data.data))
                            login({ uid: res.data.data[0].uid }).then(result=>{
                                localStorage.setItem('token', result.data.token)
                                this.$router.push('/')
                                this.isLoading = false
                            })
                            // 新用户默认关注官方账号
                            followAuthor({ uid: res.data.data[0].uid, followUid: 33 }).then(()=>{
                                console.log('关注成功')
                            })
                            if(this.requireInvitationCode){
                                // 更新邀请码
                                updateInvitationByCode({ code: this.inviteCode, uid: res.data.data[0].uid })
                            }
                        })
                    } else {
                        if(this.requireInvitationCode){
                            // 更新邀请码
                            updateInvitationByCode({ code: this.inviteCode, uid: res.data.data[0].uid })
                        }
                        localStorage.setItem('userInfo', JSON.stringify(res.data.data))
                    }
                    setTimeout(()=>{
                        this.$router.push('/')
                        this.isLoading = false
                    },0)
                })
            } else {
                this.verifyCode = ''
                this.$refs.input.focus()
                this.$notify({
                    message: '验证码错误，请重新输入',
                    duration: 2000,
                    background: '#ea6f7b'
                })
            }
      },
      verifyInvitationCode(){
        this.isLoading = true
        queryCodeNoUsed({ code: this.inviteCode }).then(res=>{
                this.isLoading = false
                // 请求接口，核对验证码，成功保存token
                if(res.data.msg_id === 'success'){
                    this.verifyIdentity()
                } else if(res.data.msg_id === 'codenotfind') {
                    this.inviteCode = ''
                    this.$refs.input.focus()
                    this.$notify({
                        message: '邀请码不存在，请重新输入',
                        duration: 2000,
                        color: '#555',
                        background: '#ebedee'
                    })
                } else if(res.data.msg_id === 'codeused') {
                    this.inviteCode = ''
                    this.$refs.input.focus()
                    this.$notify({
                        message: '邀请码已使用，请重新输入',
                        duration: 2000,
                        color: '#555',
                        background: '#ebedee'
                    })
                }
            })
      },
      timer(){
            if(this.time > 0) {
                this.time --
                setTimeout(this.timer, 1000);
            } else {
                this.time = 0
                this.showResend = false
            }
      },
      goBack(){
        this.$router.back()
      },
      handleAgree(){
        this.checked = true
        this.$refs.confirm.show('agreement')
        this.sendVerifyCode()
      },
      goUserAgreement(){
        this.$router.push('/about/user-agreement')
      },
      goPrivatePolicy(){
        this.$router.push('/about/privacy-policy')
      },
      handleChecked(){
        this.checked = !this.checked
      }
  }
}
</script>

<style scoped lang="scss">
.back-logo {
    left: 10px;
    top: 10px;
    position: absolute;
}
.bg-img {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgb(33,37,43);
    // background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898; background-blend-mode: multiply,multiply;
    z-index: -1;
}
.login-pc-box {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: left;
    .login-card {
        background: #fff;
        display: flex;
        box-sizing: border-box;
        padding: 40px;
        .left {
            width: 250px;
            padding: 10px 50px 10px 10px;
            text-align: center;
            box-sizing: border-box;
            border-right: 1px rgba(0,0,0,0.1) solid;
        }
        .right {
            flex: 1;
            box-sizing: border-box;
            padding: 10px 10px 10px 50px;
            .title {
                font-size: 22px;
                color: #333;
                font-weight: 600;
                text-align: center;
            }
            .subtitle {
                font-size: 12px;
                color: #aaa;
                margin: 10px 0 0 0;
                text-align: left;
                display: flex;
                justify-content: center;
            }
            .button-block {
                margin: 15px 0;
                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 8px;
                    height: 40px;
                    background: rgba(0,0,0,.04);
                    border-radius: 20px;
                    font-size: 16px;
                    font-weight: 500;
                    color: rgba(51,51,51,.2);
                    cursor: auto;
                    transition: all .2s;
                }
                .button-highlight {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 8px;
                    height: 40px;
                    background: rgba(0,0,0,.04);
                    border-radius: 20px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                    background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
                    cursor: pointer;
                    transition: all .2s;
                }
            }
            .input-verify {
                margin: 30px 0;
                .input-block {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #eee;
                    cursor: pointer;
                    .code-highlight {
                        font-size: 16px;
                        padding-left: 5px;
                        border-left: 1px rgba(0,0,0,0.1) solid;
                        color: #222;
                        min-width: 110px;
                        text-align: center;
                    }
                    .code-default {
                        font-size: 16px;
                        padding-left: 5px;
                        min-width: 110px;
                        text-align: center;
                        cursor: auto;
                        border-left: 1px rgba(0,0,0,0.1) solid;
                        color: rgba(34, 34, 34, 0.5);
                    }
                    input {
                        font-size: 16px;
                        color: rgba(0,0,0,0.9);
                        background: none;
                        border: none;
                        outline:none;
                        padding: 10px 10px 10px 0;
                    }
                    input :focus {
                        outline: none;
                    }
                }
                .tips {
                    width: 80%;
                    font-size: 14px;
                    color: red;
                    display: flex;
                    justify-content: center;
                    margin: 5px 0 0 30px;
                }
            }
            .input {
                margin: 30px 0;
                .input-block {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #eee;
                    span {
                        font-size: 18px;
                        padding-right: 10px;
                        border-right: 1px rgba(0,0,0,0.1) solid;
                    }
                    input {
                        width: 80%;
                        font-size: 16px;
                        color: rgba(0,0,0,0.9);
                        background: none;
                        border: none;
                        outline:none;
                        padding: 10px;
                    }
                    input :focus {
                        outline: none;
                    }
                }
                .tips {
                    width: 80%;
                    font-size: 14px;
                    color: red;
                    display: flex;
                    justify-content: center;
                    margin: 5px 0 0 30px;
                }
            }
            .agreement {
                width: 280px;
                position: relative;
                margin-top: 16px;
                padding-left: 18px;
                font-size: 12px;
                color: rgba(51,51,51,.6);
                line-height: 160%;
            }
        }
    }
}
.login-box {
    text-align: left;
    padding: 90px 30px;
    min-height: 100vh;
    box-sizing: border-box;
    background: #fff;
    .title {
        color: rgba(0,0,0,0.9);
        font-size: 26px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Microsoft soft, Arial, Helvetica, sans-serif;
        img {
            margin-right: 10px;
        }
    }
    .subtitle {
        font-size: 15px;
        color: #aaa;
        margin: 10px 0 0 0;
        text-align: left;
        display: flex;
        justify-content: center;
    }
    .input {
        margin: 40px 0 25px;
        .input-block {
            display: flex;
            justify-content: center;
            input {
                width: 80%;
                min-height: 30px;
                font-size: 24px;
                color: rgba(0,0,0,0.9);
                background: none;
                border: none;
                outline:none;
                padding: 10px;
                border-bottom: 1px solid #eee;
            }
            input :focus {
                outline: none;
            }
        }
        .tips {
            width: 80%;
            font-size: 14px;
            color: #191970;
            display: flex;
            justify-content: center;
            margin: 5px 0 0 30px;
        }
    }
    .button {
        margin: 0 auto;
        border: 1px solid rgba(255, 255, 255, 0.30);
        background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
        color: #FFF;
        width: 80%;
        height: 45px;
        line-height: 45px;
        border-radius: 30px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .checkbox {
        margin: 20px 0;
        display: flex;
        justify-content: center;
        .agreement {
            color: #969696;
            font-size: 12px;
            margin: 0 5px;
            .content {
                color: #222;
            }
        }
    }
}
.row {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    img {
        padding-right: 5px;
    }
    a {
        // color: #000;
        color: rgb(62,156,214);
    }
}
.van-loading {
    position: relative !important;
    left: 0;
    top: 0;
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    font-size: 0;
    vertical-align: middle;
    -ms-transform: none;
}
.van-notice-bar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    color: #ed6a0c;
    font-size: 14px;
    line-height: 24px;
    background-color: #fffbe8;
}
.van-checkbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    min-width: 15px;
    -webkit-user-select: none;
    user-select: none;
}
</style>
